@import 'blueprintjs-variables';
@import '~@blueprintjs/core/src/common/colors';
@import '~@blueprintjs/core/src/reset';
@import '~@blueprintjs/core/src/typography';
@import '~@blueprintjs/core/src/accessibility/focus-states';
@import '~@blueprintjs/core/src/components/index';

@import './blueprint-datetime.css';
@import '~@blueprintjs/select/src/blueprint-select';

@import '~blueprint5-datetime/lib/css/blueprint-datetime2.css';
@import 'blueprint-datetime2';

// ------------------------
// BP3 fixes for Sass (some things don't work out of the box)
// ------------------------

.bp3-menu-header > .bp3-heading,
.running-text .bp3-heading,
.bp3-heading {
  font-weight: --headingWeight;
}

.bp3-dark {
  background: $mfx-gray-6--dark;
}

.bp3-dark .bp3-navbar,
.bp3-navbar {
  background: none;
  border: none;
  box-shadow: none;
}

.bp3-button:not(.mfx-form-select__button) {
  box-shadow: none !important;
  font-size: 14px;
  font-weight: $fontWeightBold;

  letter-spacing: $mfx-letter-spacing;

  &.bp3-small {
    font-weight: normal;
    text-transform: none;
  }
}

.bp3-button.bp3-large {
  font-size: 14px;
}

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e");
}

.#{$ns}-running-text .#{$ns}-heading,
.#{$ns}-heading {
  font-weight: $headingWeight;
}

// -------------
// Breadcrumbs
//--------------

// SVGs are customized to match colors
.bp3-breadcrumbs > li:after {
  background: url('../img/blueprintjs/breadcrumbs/chevron-right.svg') no-repeat;
}

.bp3-breadcrumbs-collapsed:before {
  background: url('../img/blueprintjs/breadcrumbs/more.svg') no-repeat;
}

.bp3-breadcrumb:not(.bp3-breadcrumb-current) {
  cursor: pointer;
  outline: none;
}

.bp3-breadcrumb-current {
  font-weight: normal;
}

.bp3-breadcrumb.bp3-disabled {
  cursor: default;
}

.bp3-breadcrumb,
.bp3-breadcrumb-current,
.bp3-breadcrumbs-collapsed {
  font-size: 14px;
}

.bp3-input-action {
  .bp3-icon {
    margin: 12px;
  }
}

.bp3-input-group .bp3-input:not(:first-child) {
  padding-left: 40px;
}

.bp3-input-group.bp3-large .bp3-input {
  font-size: 14px;
}

.bp3-dark label.bp3-label {
  color: $mfx-gray-1--dark;
}

.bp3-popover-content {
  background: white;
  color: black;
}

.bp3-tooltip {
  .bp3-popover-content {
    background-color: white;
    color: black;
  }

  .bp3-popover-arrow-fill {
    fill: white;
  }
}

.bp3-tooltip.bp3-dark {
  .bp3-popover-content {
    background-color: $mfx-gray-5;
    color: $mfx-gray-2;
  }

  .bp3-popover-arrow-fill {
    fill: $mfx-gray-5;
  }
}
.cast-crew-form__name {
  .bp3-input-action {
    margin: -5px;
  }
}

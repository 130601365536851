.entity-preview {
  a {
    color: $mfx-main--light !important;
  }
  a:hover {
    color: $mfx-main !important;
  }
}

.entity-preview__thumb {
  height: 185px;
  width: 100%;
}
.entity-preview__thumb .thumbnail__content {
  border-radius: 0;
}

.entity-preview__thumb .thumbnail__type {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: fit-content;
}

.slider-select__tooltip {
  z-index: 2;
}

.slider-select__input-0:hover + .slider-select__tooltip {
  margin-left: 10px;
}

.entity-preview__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid lighten($mfx-gray-3, 20);

  .bp3-dark & {
    border-bottom: 1px solid $mfx-gray-6--dark;
  }
}

.entity-preview__title {
  width: 100%;
  h3 {
    @include word-wrap;
    font-weight: $headingWeight;
    text-align: center;
  }
  padding: 15px 20px;
}

.entity-preview__title--breadcrumbless {
  .breadcrumbs__items__list {
    display: none;
  }
}

.entity-preview__description {
  .bp3-dark & {
    color: #fff !important;
  }
}

.entity-preview-tab__no-preview {
  @include text-feedback;
}

.entity-preview__subtitle {
  padding-bottom: 10px;
  h4 {
    @include word-wrap;
    font-weight: $fontWeightRegular;
  }
}

.entity-preview__divider {
  width: 100%;
  border-bottom: 1px solid black;
  margin: 15px 0;
}

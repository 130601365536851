@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), url('../fonts/roboto-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), url('../fonts/roboto-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), url('../fonts/roboto-italic-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), url('../fonts/roboto-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), url('../fonts/roboto-bold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

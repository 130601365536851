.entity-detail {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  flex: 1;
  min-height: 1px;
  background: #fff;

  .bp3-dark & {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background: $mfx-gray-5--dark;
  }
}

.entity-detail__tabs {
  @include tabs;
}

.entity-detail__tab:not(.entity-detail__tab-content) {
  @include tab-button;
}

.entity-detail__tab-content {
  grid-column: span 2;
  .data-section-layout {
    height: 100%;
  }
}

.entity-detail__date {
  position: relative;
  padding: 20px;
  border-bottom: 1px solid darken($mfx-gray-2, 5);
  border-left: 1px solid darken($mfx-gray-2, 5);

  .bp3-dark & {
    color: $mfx-gray-1--dark;
    border-bottom: 1px solid $mfx-gray-6--dark;
    border-left: 1px solid $mfx-gray-6--dark;
  }

  &:before {
    @include heading-ribbon;
    height: 11px;
  }
}

// styling for overview tab

.entity-tab__content {
  display: grid;
  grid-template-columns: 350px 1fr;
  @include detail-page-metadata;
}

.entity-tab__content__info-wrapper {
  display: grid;
  align-content: start;
  border-right: 1px solid darken($mfx-gray-2, 5);

  .bp3-dark & {
    border-right: 1px solid $mfx-gray-6--dark;
  }
}

.entity-tab__content__title {
  padding: 0 20px;
  grid-column: span 2;
  color: $muted-text;
}

.entity-tab__content__email {
  @include word-wrap;
}

.entity-tab__content__notes {
  grid-column: span 2;
}

@include media-breakpoint-up(lg) {
  .entity-tab__content__infobox {
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .entity-tab__content__subtitle {
    margin-top: 0;
  }
}
.entity-tab__content__email {
  @include word-wrap;
}

.entity-tab__content__tag {
  margin-right: 3px;
}

.entity-detail-content--padded {
  padding: 0 20px;
}

.entity-detail__infobox-wrapper {
  display: grid;
  grid-template-columns: 1fr !important;
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.entity-detail__infobox-wrapper--business {
  display: grid;
  grid-template-columns: 1fr !important;
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
[data-mantine-color-scheme='light'] .entity-detail__infobox-wrapper--business {
  a {
    color: var(--mfx-main);
  }
}
.muted-text {
  color: $mfx-gray-4;
  .bp3-dark & {
    color: $muted-text;
  }
}

@import 'mfx-variables';

$pt-font-family: 'Roboto', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans',
  'Helvetica Neue', 'Icons16', sans-serif;

$pt-intent-primary: $mfx-main;
$pt-intent-success: $mfx-success;
$pt-intent-warning: $mfx-warning;
$pt-intent-danger: $mfx-error;

$pt-link-color: $pt-intent-primary;
$pt-dark-link-color: $pt-intent-primary;

@import '~@blueprintjs/core/src/common/variables';

$button-intents: (
  'primary': (
    $pt-intent-primary,
    $mfx-main--light,
    $mfx-main--light,
  ),
  'success': (
    $pt-intent-success,
    $pt-intent-success,
    $pt-intent-success,
  ),
  'warning': (
    $pt-intent-warning,
    $pt-intent-warning,
    $pt-intent-warning,
  ),
  'danger': (
    $pt-intent-danger,
    $pt-intent-danger,
    $pt-intent-danger,
  ),
);

// ---------------
// Custom Variables / Theme
// ---------------

$spacer: 1rem;
$font-size-base: 0.875rem;
$link-decoration: none;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: (
    $spacer * 3.5,
  ),
  7: (
    $spacer * 4,
  ),
  8: (
    $spacer * 5.5,
  ),
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/maps';

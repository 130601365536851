.access-level-red--tile {
  @include entity-tile__access-bar($optionRed);
}
.access-level-orange--tile {
  @include entity-tile__access-bar($optionOrange);
}
.access-level-yellow--tile {
  @include entity-tile__access-bar($optionYellow);
}
.access-level-green--tile {
  @include entity-tile__access-bar($optionGreen);
}
.access-level-white--tile {
  @include entity-tile__access-bar($optionWhite);
}

.analytics-filters__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 0;
  padding: 10px 20px;
  align-items: center;
  .mantine-InputWrapper-root,
  .bp3-form-group {
    margin: 0 10px 0 0 !important;
    align-self: center;
  }

  .event-analytics-tab_date-range {
    width: 240px;
  }

  .analytics-filters__selector {
    .contacts {
      height: 25px;
    }

    .bp3-tag-input .bp3-tag-input-values {
      height: 25px;
    }
  }

  .bp3-switch {
    align-self: center;
    margin-bottom: 0;
  }
  .form-multi-select .bp3-form-content {
    row-gap: 0;
    .mantine-Input-wrapper {
      margin: 0;
    }
    .mantine-PillGroup-group {
      max-height: 22px;
      overflow-y: auto;
    }
  }
  .tree-select__selected-values {
    max-height: 20px;
  }
}
[data-mantine-color-scheme='light'] .analytics-filters__wrapper {
  .mantine-Input-wrapper {
    --input-bg: var(--mfx-gray-2) !important;
    --input-color: black;
  }
  .bp5-input {
    background: var(--mfx-gray-2);
    color: black;
  }
  .include-descendants__label {
    .mantine-Switch-labelWrapper {
      color: var(--mfx-gray-5);
    }
  }
}
.analytics-filters__content-wrapper {
  @include scroll-content-wrapper;
}

.analytics-filters__tab-content {
  width: 100%;
  border-top: 1px solid darken($mfx-gray-2, 5);
  @include absolute-scroll-content;

  .bp3-dark & {
    border-top: 1px solid $mfx-gray-6--dark;
  }
}

.analytics-filters__selector {
  max-width: 300px;
  flex-grow: 2;
  margin: 0 10px 0 0 !important;
  .bp3-input {
    overflow-y: auto;
  }
  .mantine-Input-wrapper {
    margin: 0;
  }

  &.responsible_contact {
    .form-remote-select__placeholder {
      button {
        font-size: 12px;
      }
    }
  }
}

.statistic-top-card-wrapper {
  width: auto;

  .section-header__right {
    line-height: 1;
  }
}

.analytics-tab__date-range {
  margin: 10px 20px;
}

.analytics-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .bp3-form-group {
    margin: 15px;
  }
}

.analytics-filters__export-button {
  margin-left: auto;
}

.analytics-filters__reset-button {
  margin-left: 5px;
}

.analytics-list-table__contact {
  display: flex;
  flex: 1;
  align-items: center;
  .user-avatar {
    padding-top: unset;
    width: 36px;
  }
}

.analytics-list-table__contact-avatar {
  padding-top: unset;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-right: 10px;
  margin-top: -9px;
  margin-bottom: -9px;
}

.analytics-list-table__contact-name {
  max-width: 130px;
}

.analytics-list-table__organization-name {
  max-width: 130px;
}

.analytics-list-table__responsible-contact-name {
  max-width: 130px;
}

.analytics-list-table__video-title {
  max-width: 140px;
}

.analytics-tab-list {
  margin: 0 20px 20px;
}

.analytics-tab__card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 0;
}

.analytics-tab__card-content {
  flex: 1;
  padding: 10px;

  background: $mfx-gray-2;

  .bp3-dark & {
    background: $mfx-gray-4--dark;
  }
}

.analytics-tab__content {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 6fr 6fr 5fr;
  }

  padding: 10px;
}

.wizard_summary__label {
  color: $mfx-gray-1;
  font-size: 12px;
  margin: 9px 0 5px 0;

  .bp3-dark & {
    color: $mfx-gray-1--dark;
  }
}

.wizard_summary__message,
.wizard_summary__value--border {
  border: 1px dashed $mfx-gray-2;
  border-radius: 3px;
  padding: 8px;
  margin: 0 10px 0 0;
  word-break: break-word;
  font-size: 12px;
  display: flex;
  align-items: center;

  .bp3-dark & {
    color: $mfx-gray-1--dark;
    border-color: $mfx-gray-2--dark;
  }
}
[data-mantine-color-scheme='light'] .wizard_summary__value--border,
.wizard_summary__message {
  color: black;
  border-color: var(--mfx-gray-3);
}
.wizard_summary__message {
  overflow-y: auto;
  display: block;
}

$mfx-main: #137cbd;
$mfx-main--light: #2b95d6;

// DARK MODE GRAYS
$mfx-gray-1--dark: #bfccd6;
$mfx-gray-2--dark: #708a9b;
$mfx-gray-3--dark: #293742;
$mfx-gray-4--dark: #24313b;
$mfx-gray-5--dark: #202b33;
$mfx-gray-6--dark: #182026;

//LIGHT MODE GRAYS
$mfx-gray-1: #f5f8fa;
$mfx-gray-2: #ebf1f5;
$mfx-gray-3: #708a9b;
$mfx-gray-4: #5c7080;
$mfx-gray-5: #394b59;
$mfx-gray-6: #182026;

$mfx-success: #3dcc91;
$mfx-warning: yellow;
$mfx-error: #f55656;
$mfx-error-2: #ff7373;

$mfx-letter-spacing: 0.5px;

$mfx-scrollbarBG: #cfd8dc;
$mfx-thumbBG: #90a4ae;

$mfx-scrollbarBG--dark: #293742;
$mfx-thumbBG--dark: #708a9b;

$muted-text: #a7b6c2;
$headingWeight: 500;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 500;

$mfx-label-bg--dark: #1c252c;
$mfx-label-bg: #1c252c; // TODO: replace this with the light theme color here

// Option colors
$optionRed--gradient: linear-gradient(to bottom, #f55656, #8b3131);
$optionYellow--gradient: linear-gradient(to bottom, #f7e78e, #cbba5e);
$optionWhite--white--gradient: linear-gradient(to top, #c1c0c0, #ddd);

$optionRed: #f55656;
$optionOrange: #ff9f3e;
$optionYellow: #f7e78e;
$optionGreen: #3dcc91;
$optionWhite: #cdcdcd;

// color used in the checkbox tree lines e.g: mobile sync detail page
$omni-checkbox-line: #3e4e5a;
$selector-bg-color: #171e24;

.entity-list-item-row {
  @include data-section-list-item2;
  @include data-section-list-item-row;
  grid-template-columns: 16px 115px minmax(0, 1fr);
  &:hover {
    @include breadcrumbs--active;
  }
}

.entity-list-item--active {
  @include data-section-list-item--active;
  @include breadcrumbs--active;
}

.entity-list-item-row__thumb {
  .thumbnail {
    height: 68px;
    width: 120px;
  }

  .thumbnail__type {
    z-index: 3;
  }
}

.entity-list-item-row__multi-thumb {
  height: 68px;
  width: 120px;
}
.entity-list-item-row__content {
  display: flex;
  flex-direction: column;
}

.entity-list-item-row__title {
  .bp3-dark & {
    color: $mfx-gray-1;
  }

  @include entity-list-item-title__link;
}

.entity-list-item-row__subtitle {
  font-weight: normal;
}

.entity-timeline-tab {
  @include scroll-content-wrapper;

  &__content {
    @include absolute-scroll-content;
  }
}

.access-level-red {
  @include entity-list__access-level-bar($optionRed);
}

.access-level-orange {
  @include entity-list__access-level-bar($optionOrange);
}

.access-level-yellow {
  @include entity-list__access-level-bar($optionYellow);
}

.access-level-green {
  @include entity-list__access-level-bar($optionGreen);
}

.access-level-white {
  @include entity-list__access-level-bar($optionWhite);
}

.entity-list-item-row__checkbox {
  &::before {
    margin: 0;
  }
}
.searchbox__entity--deleted,
.entity-list-item--deleted {
  opacity: 0.5;

  .entity-list-item-row__title {
    text-decoration: line-through;
    a {
      text-decoration: line-through;
    }
  }
  h4 {
    text-decoration: line-through;
  }

  a {
    cursor: default;
    pointer-events: none;
  }
}

.entity-list-item--inactive {
  opacity: 0.5;
}

:root {
  // font weight
  --mantine-font-size-md: 500;
  --headingWeight: 500;
  --fontWeightRegular: 400;

  // Overwritting mantine breakpoints with bootstrap ones
  --mantine-breakpoint-xs: 576px;
  --mantine-breakpoint-sm: 768px;
  --mantine-breakpoint-md: 992px;
  --mantine-breakpoint-lg: 1200px;
  --mantine-breakpoint-xl: 1400px;

  --app-shell-header-height: 70px;
  --app-shell-footer-height: 40px;

  // Buttons
  --button-bg: transparent;
  --button-fz: 14px;
  --button-radius: 3px;
  --mantine-radius-default: 3px;

  //font weight
  --fontWeightBold: 500;
  --fontWeightLight: 300;

  //header font-weight
  --headingHeight: 500;

  // Icons
  --ti-color: 'none';
  --ti-bg: 'none';
  --ti-bd: 'none';

  // Input
  --input-bg-color: rgba(0, 0, 0, 0.3);

  // mfx color variables
  --mfx-main: #137cbd;
  --mfx-main--light: #2b95d6;
  --mfx-primary: #137cbd;
  --mfx-success: #3dcc91;
  --mfx-warning: yellow;
  --mfx-error: #f55656;
  --mfx-error-2: #ff7373;

  --mfx-muted-text: #a7b6c2;

  // DARK MODE GRAYS
  --mfx-gray-1--dark: #bfccd6;
  --mfx-gray-2--dark: #708a9b;
  --mfx-gray-3--dark: #293742;
  --mfx-gray-4--dark: #24313b;
  --mfx-gray-5--dark: #202b33;
  --mfx-gray-6--dark: #182026;
  --mfx-gray-7--dark: #30404d;

  //LIGHT MODE GRAYS
  --mfx-gray-1: #f5f8fa;
  --mfx-gray-2: #ebf1f5;
  --mfx-gray-3: #708a9b;
  --mfx-gray-4: #5c7080;
  --mfx-gray-5: #394b59;
  --mfx-gray-6: #182026;
  --mfx-gray-7: #30404d;

  //overwrite mantine colors
  --mantine-color-dark-4: var(--mfx-gray-5--dark);
  --mantine-color-error: var(--mfx-error-2);

  --mfx-disabled-form-bg: rgba(57, 75, 89, 0.5);
  --mfx-disabled-font-color: rgba(167, 182, 194, 0.6);
}

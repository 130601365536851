@import './bootstrap-variables';
@import '~bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/root';
@import './bootstrap-reboot';
//@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
//@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/forms';
//@import '~bootstrap/scss/buttons';
//@import '~bootstrap/scss/transitions';
//@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
//@import '~bootstrap/scss/nav';
//@import '~bootstrap/scss/navbar';
//@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/accordion';
//@import '~bootstrap/scss/breadcrumb';
//@import '~bootstrap/scss/pagination';
//@import '~bootstrap/scss/badge';
//@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/progress';
//@import '~bootstrap/scss/list-group';
//@import '~bootstrap/scss/close';
//@import '~bootstrap/scss/toasts';
//@import '~bootstrap/scss/modal';

//Helpers
@import '~bootstrap/scss/helpers';

//Utilities
@import '~bootstrap/scss/utilities/api';

// ------------------------
// Custom styles from extended BS maps
// ------------------------
@each $name, $value in $grays {
  .bg-gray-#{$name} {
    background-color: $value;
  }
}

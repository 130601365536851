// ---------------
// Custom Variables / Theme
// ---------------

$spacer: 1rem;
$font-size-base: 0.875rem;
$link-decoration: none;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: (
    $spacer * 3.5,
  ),
  7: (
    $spacer * 4,
  ),
  8: (
    $spacer * 5.5,
  ),
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/maps';

@import '../../assets/styles/mfx-variables';

$pt-font-family: 'Roboto', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans',
  'Helvetica Neue', 'Icons16', sans-serif;

$pt-intent-primary: $mfx-main;
$pt-intent-success: $mfx-success;
$pt-intent-warning: $mfx-warning;
$pt-intent-danger: $mfx-error;

$pt-link-color: $pt-intent-primary;
$pt-dark-link-color: $pt-intent-primary;

@import '~@blueprintjs/core/src/common/variables';

$button-intents: (
  'primary': (
    $pt-intent-primary,
    $mfx-main--light,
    $mfx-main--light,
  ),
  'success': (
    $pt-intent-success,
    $pt-intent-success,
    $pt-intent-success,
  ),
  'warning': (
    $pt-intent-warning,
    $pt-intent-warning,
    $pt-intent-warning,
  ),
  'danger': (
    $pt-intent-danger,
    $pt-intent-danger,
    $pt-intent-danger,
  ),
);

$mfx-main: #137cbd;
$mfx-main--light: #2b95d6;

// DARK MODE GRAYS
$mfx-gray-1--dark: #bfccd6;
$mfx-gray-2--dark: #708a9b;
$mfx-gray-3--dark: #293742;
$mfx-gray-4--dark: #24313b;
$mfx-gray-5--dark: #202b33;
$mfx-gray-6--dark: #182026;

//LIGHT MODE GRAYS
$mfx-gray-1: #f5f8fa;
$mfx-gray-2: #ebf1f5;
$mfx-gray-3: #708a9b;
$mfx-gray-4: #5c7080;
$mfx-gray-5: #394b59;
$mfx-gray-6: #182026;

$mfx-success: #3dcc91;
$mfx-warning: yellow;
$mfx-error: #f55656;
$mfx-error-2: #ff7373;

$mfx-letter-spacing: 0.5px;

$mfx-scrollbarBG: #cfd8dc;
$mfx-thumbBG: #90a4ae;

$mfx-scrollbarBG--dark: #293742;
$mfx-thumbBG--dark: #708a9b;

$muted-text: #a7b6c2;
$headingWeight: 500;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 500;

$mfx-label-bg--dark: #1c252c;
$mfx-label-bg: #1c252c; // TODO: replace this with the light theme color here

// Option colors
$optionRed--gradient: linear-gradient(to bottom, #f55656, #8b3131);
$optionYellow--gradient: linear-gradient(to bottom, #f7e78e, #cbba5e);
$optionWhite--white--gradient: linear-gradient(to top, #c1c0c0, #ddd);

$optionRed: #f55656;
$optionOrange: #ff9f3e;
$optionYellow: #f7e78e;
$optionGreen: #3dcc91;
$optionWhite: #cdcdcd;

// color used in the checkbox tree lines e.g: mobile sync detail page
$omni-checkbox-line: #3e4e5a;
$selector-bg-color: #171e24;

@mixin centered-layout-page {
  grid-area: form;
  align-self: center;
  justify-self: center;
  height: max-content;
  width: 100%;
  max-width: 465px;
  background: $mfx-gray-5--dark;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    padding: 30px;
  }

  &__head {
    align-self: center;
    border-top: 3px solid $mfx-main;
    padding-bottom: 25px;

    h3 {
      padding-top: map_get($spacers, 4);
      margin-bottom: 0;
    }
  }

  &__line {
    border-bottom: 1px solid $mfx-gray-6--dark;
    width: 100%;
  }

  &__note {
    height: 60px;
    background: $mfx-gray-3--dark;
    color: $mfx-gray-1--dark;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btngroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bp3-button {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__content {
      padding-left: 60px;
      padding-right: 60px;
    }

    &__btngroup {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .bp3-button {
        width: max-content;
      }
    }
  }
}

@mixin grid-layout {
  background-image: url('../../assets/img/login bg.jpg');
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 70px;
  grid-template-areas:
    'form'
    'footer';
  height: 100vh;
}

@mixin public-page-layout {
  display: grid;
  grid-template-columns: 15vw 1fr 15vw;
  grid-template-rows: 1fr 1px 5fr 70px;

  grid-template-areas:
    'heading heading heading'
    'line line line'
    '. content .'
    'footer footer footer';

  height: 100vh;

  &__head {
    grid-area: heading;
    background: $mfx-gray-5--dark;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

    h3 {
      border-top: 3px solid $mfx-main;
      padding-top: map_get($spacers, 5);
      margin-bottom: 0;
    }
  }

  &__line {
    grid-area: line;
    border-bottom: 1px solid $mfx-gray-5--dark;
  }

  &__content {
    grid-area: content;

    h4 {
      margin-top: 15px;
      border-left: 3px solid $mfx-main;
      padding-left: map_get($spacers, 3);
    }

    .running-text li {
      list-style-type: square;
    }
  }
}

@mixin mfx-form {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  margin: 1px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

@mixin data-info-text {
  display: inline-flex;
  align-items: center;

  .bp3-dark & {
    color: $muted-text;
  }

  &:not(:last-child):after {
    content: '|';
    padding: 0 map_get($spacers, 2);
  }
}

@mixin heading-ribbon($color: $mfx-main, $margin-top: 1px, $height: 15px, $left: 0, $top: auto) {
  content: '';
  left: $left;
  background: $color;
  width: 3px;
  position: absolute;
  margin-top: $margin-top;
  height: $height;
  top: $top;
}

@mixin tabs {
  display: flex;
  flex: 1;
  min-height: 1px;
  flex-direction: column;

  .bp3-tab-panel {
    margin-top: 0;
  }

  .bp3-tab-list {
    display: flex;
    align-items: center;
    padding: 0 18px;
    position: relative;

    border-bottom: 1px solid darken($mfx-gray-2, 5);

    .bp3-dark & {
      border-bottom: 1px solid $mfx-gray-6--dark;
    }

    &:before {
      @include heading-ribbon($mfx-main, 0, 15px, 0, 23px);
    }
  }
}

@mixin tab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  @include all-caps-text;
}

@mixin data-section-list-item {
  font-size: 13px;
  display: block;
  gap: 10px;
  padding: 15px 18px;
  user-select: none;
  border-bottom: 1px solid darken($mfx-gray-2, 5);
  border-right: 1px solid darken($mfx-gray-2, 5);
  position: relative;

  &:before {
    @include heading-ribbon($mfx-main, 0, 15px, 0, 0);
    display: none;
  }

  &:hover {
    background: darken($mfx-gray-1, 1);

    &__action-btn {
      display: inline-flex;
    }

    &__action-btn:not(.bp3-active) {
      display: none;
    }
  }

  .bp3-dark & {
    border-bottom: 1px solid $mfx-gray-6--dark;
    border-right: 1px solid $mfx-gray-6--dark;

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }

  &__label-left {
    display: inline-block;
    min-width: 90px;
  }

  &__label-right {
    display: inline-block;
    min-width: 70px;
  }

  &__label-right--hidden {
    display: block;
  }

  &__content {
    display: block;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__title-text {
    flex: 1;
    font-size: 14px;
    font-weight: $headingWeight;
    margin-bottom: map_get($spacers, 1);
  }

  &__type {
    writing-mode: horizontal-tb;
    text-align: left;
    text-transform: capitalize;
    text-orientation: mixed;
    font-size: 12px;
  }

  &__info-text {
    @include data-info-text;
  }

  @include media-breakpoint-up(xl) {
    &__content {
      display: grid;
      grid-gap: map_get($spacers, 2);
      grid-template-columns: minmax(0, 1fr) 240px;
      grid-template-rows: minmax(30px, auto) auto;
    }

    &__title {
      grid-column: span 2;
    }

    &__section {
      margin-bottom: 0;
    }
  }
}

@mixin data-section-list-item--active {
  min-width: fit-content;
  width: 100%;

  background: darken($mfx-gray-1, 2);

  &:before {
    display: block;
  }

  &:hover {
    background: darken($mfx-gray-1, 4);
  }

  .bp3-dark & {
    background: lighten($mfx-gray-4--dark, 2);

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }
}

@mixin data-section-list-item2 {
  min-width: 100%;

  border-bottom: 1px solid darken($mfx-gray-2, 5);
  border-right: 1px solid darken($mfx-gray-2, 5);

  &:hover {
    background: darken($mfx-gray-1, 1);

    &__action-btn {
      display: inline-flex;
    }

    &__action-btn:not(.bp3-active) {
      display: none;
    }
  }

  .bp3-dark & {
    border-bottom: 1px solid $mfx-gray-6--dark;
    border-right: 1px solid $mfx-gray-6--dark;

    &:hover {
      background: lighten($mfx-gray-4--dark, 2);
    }
  }
}

@mixin data-section-list-item-row {
  min-width: fit-content;
  display: grid;
  gap: 15px;
  grid-template-columns: 16px 80px minmax(0, 1fr);
  align-items: center;
  padding: 10px 15px;

  position: relative;

  &:before {
    @include heading-ribbon($mfx-main, 0, 100%, 0, 0);
    display: none;
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(2, 50%);
    row-gap: 5px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }

    @include entity-list-item-title__link;
  }

  &__attributes {
    display: grid;
    column-gap: 5px;
    width: 100%;
  }

  &__checkbox {
    .bp3-control {
      margin-bottom: 0;
    }
  }
}

@mixin data-section-list-item-tile {
  padding: 15px;

  &__actions {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include entity-list-item-title__link;
  }
}

@mixin scroll-content-wrapper {
  position: relative;
  overflow: auto;
  flex: 1;
  min-height: 1px;
}

@mixin absolute-scroll-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

@mixin filter-select-form {
  .bp3-button {
    &.empty {
      color: $input-placeholder-color;
    }
  }

  .bp3-button-text {
    width: 100%;
  }

  .bp3-dark & {
    .bp3-button {
      background: rgba(0, 0, 0, 0.3);

      &.empty {
        color: rgba($gray4, 0.6);
      }
    }
  }
}

@mixin checkbox {
  cursor: pointer;
  appearance: none;
  background-color: $mfx-gray-5;
  margin: 0;
  font: inherit;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
  border-radius: 3px;

  .bp3-dark & {
    background: $mfx-gray-5--dark;
  }

  &:checked {
    background-color: $mfx-main;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
  }

  &:disabled {
    cursor: default;
  }

  &:checked::before {
    content: '\2713';
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin all-caps-text {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
}

@mixin detail-page-metadata {
  &__infobox {
    display: block;
    margin: 20px 0;
    border-bottom: 1px solid darken($mfx-gray-2, 5);

    .bp3-dark & {
      border-bottom: 1px solid $mfx-gray-6--dark;
    }
  }

  &__title {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:before {
      @include heading-ribbon($mfx-main, 0, 11px);
    }

    @include all-caps-text;
  }

  &__subtitle {
    @include all-caps-text;
    font-size: 10px;
    margin-top: 10px;
  }

  &__tag {
    padding: 2px 5px;
    border-radius: 2px;
    @include all-caps-text;
    font-size: 10px;

    background: $mfx-gray-2;

    .bp3-dark & {
      color: $mfx-gray-1--dark;
      background: lighten($mfx-gray-3--dark, 4);
    }
  }
}

@mixin timeline-item-layout {
  @include scroll-content-wrapper;

  &__content {
    @include absolute-scroll-content;
  }

  &__item {
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 15px;
    align-items: center;
  }

  &__item--no-user {
    grid-template-columns: 1fr max-content;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__user {
    font-weight: 500;
  }

  &__description {
    @include word-wrap;
  }

  &__date {
    position: absolute;
    top: -20px;
    left: 0;
    font-weight: $fontWeightRegular;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin text-feedback {
  @include all-caps-text;
  font-size: 12px;
  color: $mfx-gray-3;

  .bp3-dark & {
    color: $mfx-gray-2--dark;
  }
}

@mixin entity-list-item-tile__type {
  @include all-caps-text;
  background: $mfx-label-bg;
  color: $mfx-gray-2;
  z-index: 1;
  border-radius: 3px;
  font-size: 10px;
  padding: 4px 10px;

  .bp3-dark & {
    background: $mfx-label-bg--dark;
    color: $mfx-gray-2--dark;
  }
}

@mixin breadcrumbs--active {
  .breadcrumbs__ancestor__tiles,
  .breadcrumbs__ancestor__list {
    background: linear-gradient(to left, #2e4250, #3c586a);
  }

  .breadcrumbs__ancestor__tiles:before,
  .breadcrumbs__ancestor__list:before {
    border-color: transparent #3c586a transparent transparent;
  }

  .breadcrumbs__child__list,
  .breadcrumbs__child__tiles {
    background: linear-gradient(to right, #2e4250, #3c586a);
  }

  .breadcrumbs__child__list:before,
  .breadcrumbs__child__tiles:before {
    border-color: transparent transparent transparent #3c586a;
  }
}

@mixin breadcrumbs--active-light-theme {
  .breadcrumbs__ancestor__tiles,
  .breadcrumbs__ancestor__list {
    background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
  }

  .breadcrumbs__ancestor__tiles:before,
  .breadcrumbs__ancestor__list:before {
    border-color: transparent var(--mfx-gray-3) transparent transparent;
  }

  .breadcrumbs__child__list,
  .breadcrumbs__child__tiles {
    background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
  }

  .breadcrumbs__child__list:before,
  .breadcrumbs__child__tiles:before {
    border-color: transparent transparent transparent var(--mfx-gray-3);
  }
}

[data-mantine-color-scheme='light'] .breadcrumbs__child__list {
  :hover {
    background: none;
  }
  ::before {
    border-color: transparent transparent transparent var(--mfx-gray-3);
  }
  background: linear-gradient(to right, var(--mfx-gray-1), var(--mfx-gray-3));
}

@mixin data-section-show-after-step($step) {
  @for $_ from $step through 9 {
    .size-#{$_} & {
      display: block;
    }
  }

  .size-xxl & {
    display: block;
  }
}

@mixin entity-list-item__link {
  a {
    color: inherit;
  }
}

@mixin entity-list-item-title__link {
  a {
    color: inherit !important;
  }

  a:hover {
    color: $mfx-main !important;
  }
}

@mixin entity-list__access-level-bar($color) {
  &:before {
    @include heading-ribbon($color, 2px, 16px);
  }

  .entity-list-item--active & {
    &:before {
      @include heading-ribbon($color, 0, 100%, 0, 0);
    }
  }
}

@mixin section-header__access-level-bar($color) {
  h3 {
    &:before {
      @include heading-ribbon($color, 3px);
    }
  }
}

@mixin entity-tile__access-bar--active($color) {
  &:before {
    @include heading-ribbon($color, 0.5px, calc(100% - 1.5px), 0, 0);
  }
}

@mixin entity-tile__access-bar($color) {
  &:before {
    @include heading-ribbon($color, 1px, 16px, 0, 20px);
  }

  .product-list-item-tile--active & {
    @include entity-tile__access-bar--active($color);
  }

  .asset-list-item-tile--active & {
    @include entity-tile__access-bar--active($color);
  }
}

@mixin entity-detail-transparent-content {
  background-color: transparentize(#fff, 0.2);

  .bp3-dark & {
    background-color: transparent;
  }
}

@mixin entity-detail-transparent {
  background-color: transparentize(#fff, 0.2);

  .bp3-dark & {
    background-color: transparentize($mfx-gray-5--dark, 0.2);
  }

  .data-section,
  .data-section__pagination-container,
  .data-section-sidebar,
  .data-section-sidebar__tabs,
  .section-header--use-background {
    @include entity-detail-transparent-content;
  }
}

@mixin list-title-truncate {
  display: flex;
  width: calc(100% - 15px);

  a {
    flex: 1 1 100%;
    min-width: 0;
    max-width: max-content;
  }
}

.form-action-section {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}

.entity-filters {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.entity-filters__new-filter {
  padding: 25px 18px;
}

.entity-filters__title {
  h4 {
    font-weight: $fontWeightRegular;
    @include all-caps-text;

    &:before {
      @include heading-ribbon;
      height: 10px;
    }
  }
}

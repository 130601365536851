/* Mix of styles to make bp5 daterangepicker work */

.bp5-control-group {
  transform: translateZ(0);
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.filter-date-range__input .bp5-input-group,
.filter-date-range__input .bp3-control-group > *:not(.bp3-fixed) {
  flex: 1 1 auto;
}

.bp5-input {
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 1px 1px rgba(0, 0, 0, 0.2);
  color: var(--bs-body-color);
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;

  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
  color: #f5f8fa;
}

.bp5-button {
  align-items: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;

  &.bp5-minimal {
    background: none;
    box-shadow: none;

    &:hover {
      background: rgba(143, 153, 168, 0.15);
      box-shadow: none;
      color: #1c2127;
      text-decoration: none;
    }
  }
}

.bp5-input-group {
  display: block;
  position: relative;

  .bp5-input {
    position: relative;
    width: 100%;
  }
}

.bp5-popover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4);
  transform: scale(1);
  border-radius: 3px;
  display: inline-block;
  z-index: 20;

  &.bp5-dark {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4);

    .bp5-popover-content {
      background: var(--mfx-gray-7);
      color: inherit;
    }
  }

  .bp5-popover-arrow {
    height: 30px;
    position: absolute;
    width: 30px;
  }

  .bp5-popover-content {
    border-radius: 3px;
    position: relative;
  }
}

.bp3-dark {
  background: var(--mfx-gray-6);
  scrollbar-color: #708a9b #293742;

  .bp5-popover {
    .bp5-popover-content {
      background: var(--mfx-gray-7);
      color: inherit;
    }
  }

  .bp5-datepicker {
    background: var(--mfx-gray-7);
    .bp5-icon {
      cursor: pointer;
      font-size: 14px;
      justify-content: center;
      text-align: left;

      color: #5f6b7c;
      display: inline-block;
      flex: 0 0 auto;
      vertical-align: text-bottom;
      margin: 0 -7px;
      position: relative;
    }
  }

  .bp5-menu {
    background: var(--mfx-gray-7);
    color: var(--mfx-gray-1);
  }

  .bp5-menu-item {
    color: inherit;

    &:hover {
      color: inherit;
      background-color: rgba(138, 155, 168, 0.15);
    }
  }
}

.bp5-daterangepicker-shortcuts {
  .bp5-menu {
    min-width: 120px;
    padding: 0;
  }
}

.bp5-menu {
  background: white;
  border-radius: 3px;
  color: var(--mfx-gray-6);
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 5px;
  text-align: left;
}

.bp5-menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  color: inherit;
  line-height: 20px;
  padding: 5px 7px;
  text-decoration: none;
  user-select: none;
}

.bp5-icon > svg:not([fill]) {
  fill: currentColor;
}

.bp3-menu-item > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-fill .bp3-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.bp5-html-select select,
.bp5-select select {
  align-items: center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #f6f7f9;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #1c2127;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  padding: 0 30px 0 10px;
  text-align: left;
  vertical-align: middle;
}

.bp5-datepicker-month-select select,
.bp5-datepicker-year-select select {
  font-weight: 600;
  padding-left: 5px;
  padding-right: 16px;
}

.bp5-html-select.bp5-minimal select,
.bp5-select.bp5-minimal select {
  background: none;
  box-shadow: none;
  color: inherit;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.1dpcm) {
  .bp5-html-select.bp5-minimal select,
  .bp5-select.bp5-minimal select {
    option {
      background-color: var(--mfx-gray-7--dark);
      :hover {
        background-color: var(--mfx-gray-4);
      }
    }
  }
}

.bp5-popover-arrow-fill {
  fill: var(--mfx-gray-7);
}

.bp5-input-group .bp5-input-action,
.bp5-input-group > .bp5-button,
.bp5-input-group > .bp5-icon,
.bp5-input-group > .bp5-input-left-container {
  position: absolute;
  top: 0;
}

.bp5-input-group .bp5-input-action,
.bp5-input-group > .bp5-button,
.bp5-input-group > .bp5-icon,
.bp5-input-group > .bp5-input-left-container {
  right: 0;
}

.bp5-button .bp5-icon:first-child:last-child,
.bp5-button .bp5-spinner + .bp5-icon:last-child {
  margin: 0 -7px;
}

.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover,
.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus {
  background: var(--mfx-gray-4);
  color: white;
}

.bp5-date-input {
  .bp5-input-action {
    display: flex;
    flex-direction: row;
  }
}

.rdp-nav {
  position: relative;
  top: -2px;
}

.bp5-timepicker {
  .bp5-timepicker-input-row {
    background: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
  }

  .bp5-timepicker-input {
    color: var(--mfx-gray-1);
  }
}

.bp5-datepicker-caption {
  .bp5-html-select select {
    border: none;
    outline: none;
    background: none;
    box-shadow: none;
    color: inherit;
  }
}

.bp5-datepicker-navbar {
  & > {
    background: none;
  }
}

.bp5-datepicker {
  background: var(--mfx-gray-7);
  border-radius: 3px;
  padding: 5px;
  user-select: none;

  .rdp-day {
    background: none;
    color: white;

    &:hover {
      background: var(--mfx-gray-4);
      color: white;
    }

    &.rdp-day_selected {
      background-color: var(--mfx-main);
      border-radius: 3px;
      color: white;

      &:hover {
        background: var(--mfx-main--light);
        color: white;
      }
    }
  }
}

.bp5-daterangepicker-timepickers {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.bp5-daterangepicker {
  background: var(--mfx-gray-7);

  &.bp5-datepicker {
    .rdp-day_range_middle {
      background-color: var(--mfx-gray-5);

      &:hover {
        background: var(--mfx-gray-4);
      }
    }
  }
}

.bp5-daterangepicker.bp5-datepicker {
  .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end),
  .rdp-day_hovered:not(.rdp-day_selected) {
    background-color: #394b59;
    color: white;
  }
}

[data-mantine-color-scheme='light'] {
  .mfx-date-range {
    .bp5-input {
      background: var(--mfx-gray-2);
    }
  }
  .bp5-timepicker-input {
    color: black;
  }
}
